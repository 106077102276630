.card-main {
    position:relative;
    height: 100%;
    width: 100%;
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    /* margin: 0 auto; */
    padding: 1vh 1vh 1vh 1vh;
    /* opacity: 0.6; */
    font-family: 'Poppins', sans-serif;
    /* background-image: url('../static/images/melgen.png'); */
    background-size: cover;
    background-position: center;
    /* background-color: rgba(65, 57, 57, 0.687); */
    border-radius: 10%;
    
    /* z-index: -1; */
}



.front-title {
    position: absolute;
    top: 10%; /* Vertically center the text */
    left: 50%; /* Horizontally center the text */
    transform: translate(-50%, -50%); /* Center the text perfectly */
    font-size: 4vh;
    opacity: 1;
    width: 80%;
    transition: opacity 0.3s;
    text-shadow: 1px 1px 2px rgb(83, 97, 133);
    color: #dbeeff;
    font-family: 'Poppins', sans-serif;

}

.card-main:hover > .front-title {
    opacity: 0;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Preserve aspect ratio and cover the entire container */
    border-radius: 10%;
    filter: blur(0px) contrast(70%) brightness(100%); /* Apply the initial blur */
    /* filter: contrast(50%); */
    transition: filter 0.4s, width 1s;
    box-shadow: 5px 5px 4px rgba(60, 67, 104, 0.617);
}

.card-main:hover > .image {
    filter: blur(8px) contrast(80%) brightness(50%);
    width: 80%
}


.text {
    position: absolute;
    top: 65%; /* Vertically center the text */
    left: 50%; /* Horizontally center the text */
    transform: translate(-50%, -50%); /* Center the text perfectly */
    z-index: 1; /* Place the text above the image */
    opacity: 0;
    transition: top 0.3s;
    font-family: 'Poppins', sans-serif;
}

.text a {
    text-decoration: none;
}

.card-main:hover > .text {
    top: 50%; /* Vertically center the text */
    opacity:1;

}

.card-main:hover > .info {
    opacity: 1;
    
}


.title {
    display: flex; /* Use Flexbox */
    justify-content: center; 
    align-items: center; /* Horizontally center elements */
    margin: 0 auto;
    width: 100%;
    height: 100%;
    font-size: 3.2vh;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    color: rgb(192, 214, 242);
    z-index: 1;
    /* background-image: inherit; */
}

.info {
    font-family: 'Poppins', sans-serif;
    color: rgb(235, 235, 235);
    font-size: 1.8vh;
    font-weight: 600;
    /* opacity: 0; */
    padding-top: 2.2vh;
    transition: opacity 0.3s ease-in-out;
    

}


.container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust to your desired height */
}

.btn3 {
    background-color: #6a90cd9e;
    border: none;
    color: rgba(84, 161, 193, 0.809);
    padding: 12px 16px;
    font-size: 1.6vh;
    cursor: pointer;
    border-radius: 5px;
    margin: 10px;
    border: 1px solid rgba(251, 251, 251, 0);
  }

.btn3:hover {
    border: 1px solid rgb(251, 251, 251, 1);
  }

/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #5b82a9d7;
  /* border: 1px solid #fff; */
}

