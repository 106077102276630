/* Contact.css */

.container-main {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100%;
    width: 100%;
}
/* 
.left-col {
    float: left;
    width: 30%;

} */

.contact-content {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2vh;
    width: 85%;
    height: 80vh;
    opacity: 1;
    /* animation: rise 1s forwards; */
    
    /* background-color: rgba(0, 0, 0, 0.61); */
    border: 5px solid rgba(242, 242, 242, 0);
    border-radius: 10%;
    
}


.row {
    display: flex; /* Create a flex container for each row */
    justify-content: space-between; /* Space elements evenly within each row */
    width: 100%; /* Ensure rows occupy full container width */
    padding: 0vh; /* Optional: Add spacing between rows */
}


.block {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    /* padding: 1vh 1vh 1vh 1vh; */
    /* margin-left: 2vh; */
    /* margin-top: 2vh; */
    width: 100%;
    height: 100%;
    border: 1vh;
    border-radius: 10%;
    animation: breathIn 1s forwards;
    opacity:0;
}

@keyframes breathIn {

    to {
        /* backdrop-filter: blur(30px) contrast(90%) brightness(110%); */
        opacity:1;
    }
    
}


.connect {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    position: sticky;
    /* padding: 1vh 1vh 1vh 1vh; */
    top: 0%;
    left: 100%;
    /* transform: translate(-8%, -70%); */
    width: 80%;
    /* margin-left: 2vh; */
    /* margin-top: 2vh; */

}

.connect h1 {
    font-size: 10vh;
    font-weight: 500;
    color: #435071f7;
}

.connect p {
    font-size: 2.5vh;
    color: #435071f7;
}

