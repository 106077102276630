/* Contact.css */
/* 
.left-col {
    float: left;
    width: 30%;

} */

/* Default link color */
a {
  color: #254657;
}

/* Visited link color */
a:visited {
  color: #254657;
}

/* Hovered link color */
a:hover {
  color: #146b97;
}

/* Active link color (while being clicked) */
a:active {
color: #146b97;

}

.about {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1vh;
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif; 
    
    /* background-color: rgba(0, 0, 0, 0.076); */
    /* border: 5px solid rgba(242, 242, 242, 0); */
    /* border-radius: 10%; */
    /* backdrop-filter: blur(40px) brightness(110%) contrast(90%); */
    
    
}



.about-row {
    display: flex; /* Create a flex container for each row */
    /* justify-content: space-between;  */
    width: 100%; /* Ensure rows occupy full container width */
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-3%, 2%);
    animation: breathIn 1s forwards;
    opacity:0;
}

/* Inner div with styling */
.about-section-wrapper {
    display: flex; /* Use Flexbox */
    flex-direction: column;
    justify-content: center; /* Vertically center elements */
    width: 95%;
    height: 95%;
     border: 1vh;
     border-radius: 5%;
    background-color: rgba(176, 196, 222, 0.6); /* Light steel blue with 70% opacity */
    padding: 4vh; /* Padding for inner elements */
    box-shadow: 0 4vh 6vh rgba(0, 0, 0, 0.4); /* Optional: adds a subtle shadow for depth */
}

@keyframes breathIn {

    to {
        /* backdrop-filter: blur(30px) contrast(90%) brightness(110%); */
        opacity:1;
    }
    
}

.about-block {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    /*align-items: center; !* Horizontally center elements *!*/
     margin-left: 2vh;
     margin-top: 2vh;
    width: 100vh;
    height: 80%;
     border: 1vh;
     border-radius: 10%;
}

.about-section {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    text-align: left;
    /*align-items:left; !* Horizontally center elements *!*/
    position: sticky;
    font-family: 'Poppins', sans-serif;
    color: #3e4453;
    /* padding: 1vh 1vh 1vh 1vh; */
    top: 50%;
    left: 90%;
    transform: translate(-10%, -5%);
    width: 100%;
    height: 70%;
    /* margin-left: 2vh; */
    margin-top: 20vh;


}

.about-section, .about-center h1 {
    font-size: 40px;
    font-weight: 300;
    color: #3e4453;
    /* margin-bottom: 1vh; */
    /* margin-top: 1vh; */
}

.about-section, .about-center p {
    font-size: 15px;
    font-weight: 300;
    color: #3e4453;
    /* margin-bottom: 1vh; */
    /* margin-top: 1vh; */
}



.about-center {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    text-align: left;
    /*align-items:left; !* Horizontally center elements *!*/
    position: sticky;
    font-family: 'Poppins', sans-serif;
    color: #435071f7;
    /* padding: 1vh 1vh 1vh 1vh; */
    top: 0%;
    left: 0%;
    /* transform: translate(-50%, 50%); */
    width: 75%;
    height: 80%;
    animation: breathIn 1s forwards;
    opacity:0;
    /* margin-left: 2vh; */
    /* margin-top: 20vh; */

}

.bio-text {
    overflow: auto;
    font-size: 16px;
    height: 80%;
    width: 100%;
}


.bio {
    /* max-width: 80%; */
    /* max-height: 80%; */
    transform: translate(0%, 18%);
    width: 65%;
    max-height: 400%;
    display: block; 
    margin: 0 auto;
    border-radius: 10%;
    /* filter: blur(0px) contrast(120%) brightness(100%); */
    transition: filter 0.4s;
    opacity:0;
    animation: rise 1s forwards;
}

@keyframes rise {

    to {
        opacity: 1;
    }
}
/* img:hover {
    filter: blur(8px) contrast(80%) brightness(50%);
} */



.horizontal-line {
    border-top: 2px solid #435071e0; /* You can adjust the color, width, and style */
    width: 100%; /* Set the width of the line */
    margin-top: 0.5vh; /* Add spacing above and below the line */
    margin-bottom: 2vh;
  }

  .read-more-blur {
    position: absolute;
    width:100%;
    height: 15%;
    bottom:0;
    border: 1px solid #435071e0;
    z-index: 1;
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    text-align: center;

    /*color: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));*/
    background-size: cover;
    background-position: center;
    /* color: #435071e0; */
    backdrop-filter: blur(5px) grayscale(100%);
    background-color: #4f77ceb3;

  }