
.cover-heading {

    font-size: 60px;

    font-weight: 600;

    /* padding: 20px 0 20px 0; */

    color: #394668;
    font-family: 'Poppins', sans-serif;

    text-align: center;

    /* text-shadow: 0 10px 30px rgba(59, 65, 95, 0.5); */

}

@media (min-width: 1200px) {
  .lp-container {
    position: fixed;
    top: 25%;
    left: 35%;
    margin-right: 10%;
    /* transform: translate(20%, 90%); */
    animation: fade-in 2.2s ease-in-out forwards; 
    opacity: 0;
  }
}

@media (min-width: 0px) and (max-width: 1200px){
  .lp-container {
    position: absolute;
    width: 80%;
    top: 30%;
    left: 10%;
    /* margin-right: 10%; */
    /* transform: translate(50%, 50%); */
    /* animation: fade-in 2.2s ease-in-out forwards;  */
    opacity: 1;
  }
}

@keyframes fade-in {
  to {
      opacity: 1;
      left: 45%;
      /* transform: translate(40%, 80%); */
  }
}


.h2 {
    font-size: 25px;
    color: #36415ed0;
    font-family: 'Poppins', sans-serif; 

}

.inner {
    padding: 20px;
  }
  
  .btn1 {
    background-color: #416bb08b;
    border: none;
    color: rgba(161, 196, 211, 0.71);
    /* padding: 12px 16px; */
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    margin: 10px;
  }

  .btn2 {
    background-color: #154ca59e;
    border: none;
    color: rgba(161, 196, 211, 0.71);
    padding: 7px 7px;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    margin: 8px;
    border: 1px solid rgba(251, 251, 251, 0);

  }

  .btn2:hover {
    border: 1px solid rgb(251, 251, 251);
    color: rgb(211, 211, 211);
    
  }

.row {
    display: flex; /* Create a flex container for each row */
    justify-content: space-between; /* Space elements evenly within each row */
    width: 100%; /* Ensure rows occupy full container width */
    padding: 0vh; /* Optional: Add spacing between rows */
}


.block {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    /* padding: 1vh 1vh 1vh 1vh; */
    /* margin-left: 2vh; */
    margin-bottom: 5vh;
    width: 10vh;
    height: 35vh;
    border: 1vh;
    border-radius: 10%;
}

img {
  max-width: 100%; /* Ensure the image width doesn't exceed the div's width */
  max-height: 100%; /* Ensure the image height doesn't exceed the div's height */
  display: block; /* Remove any default inline spacing */
  margin: 0 auto; /* Center the image horizontally (optional) */
}

.icon {
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #5468a8d7;
  /* border: 1px solid #fff; */
}

