.thin-content {
    overflow: auto;
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    align-items: center; /* Horizontally center elements */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2vh 2vh 2vh 2vh;
    width: 90%;
    height: 80vh;
    
    opacity: 0;
    animation: rise 1s forwards;
    
    
    /* background-color: rgba(0, 0, 0, 0.61); */
    border: 5px solid rgba(242, 242, 242, 0);
    border-radius: 10%;
    
}

.thin-row {
    display: flex; /* Create a flex container for each row */
    justify-content: space-between; /* Space elements evenly within each row */
    width: 100%; /* Ensure rows occupy full container width */
    padding: 0vh; /* Optional: Add spacing between rows */
}