.background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    /* height: auto; */
    object-fit: cover;
    left: 0;
    top: 0;
    z-index: -1;
    filter: blur(0px); /* Apply the initial blur */
    opacity:1

}

@media (max-width: 768px) {
    .background-video {
        display: none;
    }
}

.video-active {

    filter: brightness(90%) blur(90px); /* Apply the initial blur */
}