

.content {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2vh;
    width: 85%;
    height: 90vh;
    
    opacity: 0;
    animation: rise 1s forwards;
    
    
    /* background-color: rgba(0, 0, 0, 0.61); */
    border: 5px solid rgba(242, 242, 242, 0);
    border-radius: 10%;
    
}



@keyframes rise {

    100% {
        opacity: 1;
    }
}

.content-row {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin: 10px; */
    /* padding: 10px; */
}

.row {
    display: flex; /* Create a flex container for each row */
    justify-content: space-between; /* Space elements evenly within each row */
    width: 100%; /* Ensure rows occupy full container width */
    padding: 0vh; /* Optional: Add spacing between rows */
}


.project-badge {
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    padding: 1vh 1vh 1vh 1vh;
    /* margin-left: 2vh; */
    /* margin-top: 2vh; */
    width: 90vh;
    height: 35vh;
    border: 5px;
    border-radius: 10%;
}

.background-blur {
    
    display: flex; /* Use Flexbox */
    flex-direction: column; 
    justify-content: center; /* Vertically center elements */
    align-items: center; /* Horizontally center elements */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100.01%;
    /* backdrop-filter: blur(40px) brightness(110%) contrast(90%); */
    
}