
.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Puts the background behind othexr content */
}

.background-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: blur(0px); /* Apply the initial blur */
    animation: unblurBackground 2s forwards;
}


@keyframes unblurBackground {
    from {
        filter: blur(0px); /* Starting blur */
    }
    to {
        filter: blur(0); /* Ending blur */
    }
}

.background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    /* height: auto; */
    object-fit: cover;
    left: 0;
    top: 0;
    z-index: -1;
    filter: blur(0px); /* Apply the initial blur */

}
