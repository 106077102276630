

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.no-scroll {
  overflow: hidden;
} 


/* stylelint-disable property-no-vendor-prefix */

/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: #81a8db;
}

/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  /* background-color: #8996c1cb; */
  /* border: 1px solid #fff; */
}


/*
 * Base structure
 */

html,
body {
  height: 100%;
}
body {
  /* color: #401717; */
  height: 100%;
  background: url(static/images/bvpi.png) no-repeat center center fixed;
  /* filter: contrast(20%);  */
  /* filter: blur(10px); Apply the initial blur */
  /* animation: unblurBackground 2s forwards; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* background-image: url("../static/images/landing.jpg"); */
  text-align: center;
}



/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
}
.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}
.cover-container {
  margin-right: auto;
  margin-left: auto;
}

/* Padding for spacing */
.inner {
  padding: 30px;
}


/*
 * Header
 */
.masthead-brand {
  margin-top: 10px;
  margin-bottom: 10px;
}

.masthead-nav > li {
  display: inline-block;
}
.masthead-nav > li + li {
  margin-left: 20px;
}
.masthead-nav > li > a {
  padding-right: 0;
  padding-left: 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff; /* IE8 proofing */
  color: rgba(255, 255, 255, .75);
  border-bottom: 2px solid transparent;
}
.masthead-nav > li > a:hover,
.masthead-nav > li > a:focus {
  background-color: transparent;
  border-bottom-color: #a9a9a9;
  /* border-bottom-color: rgba(127, 115, 115, 0.25); */
}
.masthead-nav > .active > a,
.masthead-nav > .active > a:hover,
.masthead-nav > .active > a:focus {
  color: #fff;
  border-bottom-color: #fff;
}

@media (min-width: 768px) {
  .masthead-brand {
    float: left;
  }
  .masthead-nav {
    float: right;
  }
}


/*
 * Cover
 */

.cover {
  padding: 0 20px;
}
.cover .btn-lg {
  padding: 10px 20px;
  font-weight: 700;
}


/*
 * Footer
 */

.mastfoot {
  color: #999; /* IE8 proofing */
  color: rgba(255, 255, 255, .5);
}


/*
 * Affix and center
 */

@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    position: fixed;
    top: 0;
    margin:auto;
    width:50%;
  }
  .mastfoot {
    position: fixed;
    bottom: 0;
  }
  .site-wrapper-inner {
    vertical-align: middle; /* Start the vertical centering */
  }
  .masthead,
  .mastfoot,
  .cover-container {
    width: 100%; /* Must be percentage or pixels for horizontal alignment */
  }
}

@media (min-width: 992px) {
  .masthead,
  .mastfoot,
  .cover-container {
    width: 700px;
  }
}

